import React, { useState, useEffect, useCallback } from "react";
import { Outlet, Link } from "react-router-dom";
import moment from "moment";
import CONFIG from "@/config";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import Web3 from "@models/web3";
import * as Promise from "bluebird";

import Header from "@/layout/header";
import Footer from "@/layout/footer";
import "./index.scss";
import {
  getTopicsByAddress,
  getReplysByAddress,
  getNodes,
  getAddressInfo,
} from "@models/api";

// socal
import blockscan from "@assets/assets/blockscan.svg";
import opensea from "@assets/assets/opensea.svg";
import debank from "@assets/assets/debank.svg";
// socal
import discord from "@assets/social/discord.svg";
import github from "@assets/social/github.svg";
import linkedin from "@assets/social/linkedin.svg";
import telegram from "@assets/social/telegram.svg";
import twitter from "@assets/social/twitter.svg";
import youtube from "@assets/social/youtube.svg";
import website from "@assets/social/website.svg";
// chat
import chat from "@assets/chat/blockscan.svg";

export default function Address() {
  /*==================================================
  Data
  ==================================================*/
  console.log("window.location.pathname", window.location.pathname.slice(1));
  const address = window.location.pathname.slice(1);

  const [addressInfo, setAddressInfo] = useState({
    name: "",
  });
  const [list, setList] = useState([]);
  const [replys, setReplys] = useState([]);
  const [nodes, setNodes] = useState([]);

  const { web3, currentAccount } = Web3.useContainer();
  const isAddress = web3 && web3.utils.isAddress(address);

  /*==================================================
  Function
  ==================================================*/
  const showNodeInfo = useCallback(
    (node_id) => {
      if (nodes.length) {
        const info = nodes.find((item) => item.value === Number(node_id));
        return info ? info.name : "未知";
      } else {
        return node_id;
      }
    },
    [nodes]
  );
  /*==================================================
  Handle
  ==================================================*/

  /*==================================================
  UseEffect
  ==================================================*/
  useEffect(() => {
    (async () => {
      const nodes = getNodes();
      const ads = getAddressInfo(address);

      const promiseAllResult = await Promise.map(
        [nodes, ads],
        function (thing) {
          return new Promise(function (resolve, reject) {
            resolve(thing);
          });
        },
        {
          concurrency: 5,
        }
      );
      console.log("addressResult", ads);

      if (promiseAllResult[0].code === 200) {
        const opts = [];
        promiseAllResult[0].data.forEach((item) => {
          opts.push({
            value: item.index,
            name: item.name,
            label: item.aliases ? `${item.name} (${item.aliases})` : item.name,
          });
        });
        setNodes(opts);
      }
      if (promiseAllResult[1].code === 200 && promiseAllResult[1].data.length) {
        setAddressInfo(promiseAllResult[1].data[0]);
      }
    })();
  }, [address]);
  useEffect(() => {
    if (addressInfo) {
      document.title = `${CONFIG.WEBSITE} > ${
        addressInfo.name ? addressInfo.name : address
      }`;
    }
  }, [addressInfo]);

  useEffect(() => {
    (async () => {
      let result = await getTopicsByAddress(address, 0, 5);
      let resultReplys = await getReplysByAddress(address, 0, 5);

      if (result.code === 200) {
        setList(result.data);
      }
      if (resultReplys.code === 200) {
        setReplys(resultReplys.data);
      }
    })();
  }, []);
  /*==================================================
  DOM
  ==================================================*/
  return (
    <>
      <Header />
      <div className="core address">
        <div className="container">
          <div className="address-wrap">
            {addressInfo && (
              <div className="node-info">
                <div className="node-img">
                  {addressInfo.avatar ? (
                    <img
                      src={
                        addressInfo.avatar.indexOf("http") === 0
                          ? `${addressInfo.avatar}`
                          : `https://${addressInfo.avatar}`
                      }
                      className="avatar"
                      alt="avatar"
                    />
                  ) : (
                    <Jazzicon
                      diameter={120}
                      paperStyles={{
                        borderRadius: "5px",
                        verticalAlign: "middle",
                      }}
                      seed={jsNumberForAddress(address)}
                    />
                  )}
                </div>
                <div className="node-des">
                  <div className="flex">
                    <h1 className="node-name">
                      {addressInfo.name
                        ? addressInfo.name
                        : address.slice(0, 8)}
                    </h1>
                    <a
                      href={`https://chat.blockscan.com/index?a=${address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="start a chat"
                    >
                      <img src={chat} className="chat" alt="chat" />
                    </a>
                  </div>
                  <div className="address-assets">
                    <ul>
                      {isAddress && (
                        <>
                          <li>
                            <a
                              href={`https://blockscan.com/address/${address}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={blockscan} alt="blockscan" />
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://debank.com/profile/${address}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={debank} alt="debank" />
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://opensea.io/${address}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={opensea} alt="opensea" />
                            </a>
                          </li>
                        </>
                      )}
                      {/* 媒体 */}
                      {addressInfo.website && (
                        <li>
                          <a
                            href={`https://${addressInfo.website}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Website"
                          >
                            <img
                              src={website}
                              className="website"
                              alt="website"
                            />
                          </a>
                        </li>
                      )}
                      {addressInfo.twitter && (
                        <li>
                          <a
                            href={`https://twitter.com/${addressInfo.twitter}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Twitter"
                          >
                            <img
                              src={twitter}
                              className="twitter"
                              alt="twitter"
                            />
                          </a>
                        </li>
                      )}
                      {addressInfo.youtube && (
                        <li>
                          <a
                            href={`https://www.youtube.com/channel/${addressInfo.youtube}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Youtube"
                          >
                            <img
                              src={youtube}
                              className="youtube"
                              alt="youtube"
                            />
                          </a>
                        </li>
                      )}
                      {addressInfo.telegram && (
                        <li>
                          <a
                            href={`https://t.me/${addressInfo.telegram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Telegram"
                          >
                            <img
                              src={telegram}
                              className="telegram"
                              alt="telegram"
                            />
                          </a>
                        </li>
                      )}
                      {addressInfo.github && (
                        <li>
                          <a
                            href={`https://github.com/${addressInfo.github}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Github"
                          >
                            <img src={github} className="github" alt="github" />
                          </a>
                        </li>
                      )}
                      {addressInfo.discord && (
                        <li>
                          <a
                            href={`https://discord.com/invite/${addressInfo.discord}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Discord"
                          >
                            <img
                              src={discord}
                              className="discord"
                              alt="discord"
                            />
                          </a>
                        </li>
                      )}
                      {addressInfo.linkedin && (
                        <li>
                          <a
                            href={`https://www.linkedin.cn/incareer/in/${addressInfo.linkedin}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Linkedin"
                          >
                            <img
                              src={linkedin}
                              className="linkedin"
                              alt="linkedin"
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="address-assets">
                    <div className="flex">
                      {currentAccount &&
                        currentAccount.toLowerCase() ===
                          address.toLowerCase() && (
                          <>
                            <Link to={`/a/${address}`}>编辑个人信息</Link> /
                            <Link to={`/a/${address}`}>查看我的收藏</Link>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="all-topics">
            <div className="area-top flex justify">
              <h2>最近主题</h2>
              <Link className="more" to={`/ts/${address}`}>
                More
              </Link>
            </div>
            {list.length ? (
              <ul className="topic-list">
                {list.map((item) => {
                  return (
                    <li key={item.transaction_hash}>
                      <div className="flex">
                        <div className="reply-wrap">
                          <div className="reply">{item.total_reply}</div>
                        </div>
                        <div className="topic">
                          <Link to={`/t/${item.transaction_hash}`}>
                            <p>{item.title}</p>
                          </Link>

                          <p className="topic-info">
                            <span>
                              <Link to={`/n/${item.node}`}>
                                {showNodeInfo(item.node)}
                              </Link>
                            </span>
                            <span>
                              By:
                              <Link to={`/${item.from_address}`}>
                                {item.from_address_name
                                  ? item.from_address_name
                                  : item.from_address.slice(0, 8)}
                              </Link>
                            </span>
                            <span>
                              发布于:
                              <i>
                                {moment(item.timestamp * 1000).format(
                                  "MM-DD HH:mm"
                                )}
                              </i>
                            </span>

                            {item.lastest_reply_address && (
                              <>
                                <span>
                                  最后回复来自:
                                  <Link to={`/${item.lastest_reply_address}`}>
                                    {item.lastest_reply_name
                                      ? item.lastest_reply_name
                                      : item.lastest_reply_address.slice(0, 8)}
                                  </Link>
                                  {moment(
                                    item.lastest_reply_timestamp * 1000
                                  ).fromNow()}
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="null">暂无</p>
            )}
          </div>
          <div className="all-replys">
            <div className="area-top flex justify">
              <h2>最近回复</h2>
              <Link className="more" to={`/rs/${address}`}>
                More
              </Link>
            </div>
            {replys.length ? (
              <ul>
                {replys.map((item) => {
                  return (
                    <li className="reply-item" key={item.transaction_hash}>
                      <div className="reply-top flex justify">
                        <Link to={`/t/${item.topic_hash}`}>
                          {item.topic_title
                            ? item.topic_title
                            : `${item.topic_hash.slice(0, 20)}...`}
                        </Link>
                        <p className="reply-info">
                          <span>
                            发布于:
                            <i>
                              {moment(item.timestamp * 1000).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </i>
                          </span>
                          <span>
                            网络: {CONFIG.networks[item.location]?CONFIG.networks[item.location].name:"--"}
                          </span>
                          {/* <span>类型: {item.event}</span> */}
                        </p>
                      </div>
                      <p>{item.content}</p>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="null">暂无</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <Outlet />
    </>
  );
}
