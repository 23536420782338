import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Storage from "@models/storage";
import { formatAddress } from "@utils";
import CONFIG from "@/config";
import Web3 from "@models/web3";
import Modal from "@/layout/modal";

import "./index.scss";

// network
import c1 from "@assets/network/ethereum.svg";
import c3 from "@assets/network/avalanche.svg";
import c4 from "@assets/network/bsc.svg";
import c66 from "@assets/network/optimism.svg";
import c31337 from "@assets/network/harmony.svg";
import c137 from "@assets/network/polygon.svg";
const network = {
  c1: c1,
  c3: c3,
  c5: c4,
  c65: c66,
  c137: c137,
  c31337: c31337,
};

// Wallet Connected
function WalletConnected() {
  /*==================================================
  Data
  ==================================================*/
  const [shortName, setShortName] = useState("-");
  const { currentAccount, disconnect } = Web3.useContainer();
  const { networkId } = Storage.useContainer();

  /*==================================================
  Function
  ==================================================*/

  /*==================================================
  Handle
  ==================================================*/

  /*==================================================
  UseEffect
  ==================================================*/
  useEffect(() => {
    let targetNetWork = CONFIG.chains.find((element) => {
      return element.networkId === Number(networkId);
    });
    setShortName(targetNetWork.shortName);
  }, [networkId]);

  /*==================================================
  DOM
  ==================================================*/
  return (
    <>
      <div className="wallet-connected">
        <div className="btn-after-connect">
          <span>{shortName.toUpperCase()}</span>
          <Link to={`/${currentAccount}`}>
            &nbsp;({formatAddress(currentAccount)}
            )&nbsp;
          </Link>
          <span
            className="close-link emoji"
            onClick={disconnect}
            alt="disconnect"
          >
            ❌
          </span>
        </div>
      </div>
    </>
  );
}

// Wallet Not Connected
function WalletNotConnected() {
  /*==================================================
  Data
  ==================================================*/
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { networkId } = Storage.useContainer();
  // const { networkId, setWalletType } = Storage.useContainer();
  const { connect } = Web3.useContainer();
  const [preChainId, setPreChainId] = useState(Number(networkId));

  /*==================================================
  Function
  ==================================================*/
  const handleChangeChain = (networkId) => {
    setPreChainId(networkId);
    handleConnect(networkId, CONFIG.DEFAULT_WALLET_TYPE);
  };
  /*==================================================
  Handle
  ==================================================*/
  const handleConnect = async (network_id, wallet_type) => {
    if (!(network_id && wallet_type)) {
      return setIsModalVisible(true);
    }
    try {
      let result = await connect(network_id, wallet_type);
      if (!result) {
        setPreChainId(Number(networkId));
        setIsModalVisible(false);
      }
    } catch (error) {
      // If it is a rollback, continue to let the user choose
      if (error === "rollback") {
        return handleConnect();
      }
    }
  };

  /*==================================================
  DOM
  ==================================================*/
  return (
    <div className="wallet-not-connected">
      <button
        className="btn-connect-wallet"
        onClick={() => setIsModalVisible(true)}
      >
        <span className="emoji">🔗</span> 连接钱包
      </button>
      <Modal
        isVisible={isModalVisible}
        title="选择你要使用的网络"
        content={Object.keys(CONFIG.networks).map((network_id) => {
          let element = CONFIG.networks[Number(network_id)];
          return (
            <button
              key={element.chainId}
              className={element.chainId === preChainId ? "active" : ""}
              onClick={() => {
                handleChangeChain(element.chainId);
              }}
            >
              <img
                src={network[`c${element.chainId}`]}
                className="chain-icon"
              />
              {element.name}
            </button>
          );
        })}
        footer={
          <button onClick={() => setIsModalVisible(false)}>
            <span className="emoji">✖️</span> 关闭
          </button>
        }
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
}

function Wallet() {
  /*==================================================
  Data
  ==================================================*/
  const { currentAccount } = Web3.useContainer();

  /*==================================================
  Function
  ==================================================*/

  /*==================================================
  Handle
  ==================================================*/

  /*==================================================
  UseEffect
  ==================================================*/

  /*==================================================
  DOM
  ==================================================*/
  return (
    <div className="wallet-area">
      {currentAccount ? <WalletConnected /> : <WalletNotConnected />}
    </div>
  );
}

export default Wallet;
