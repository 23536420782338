import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
// import { Link } from "react-router-dom";
// import moment from "moment";
import CONFIG from "@/config";
import Web3 from "@models/web3";

import Header from "@/layout/header";
import Footer from "@/layout/footer";
// import Storage from "@models/storage";
import Utils from "@models/utils";
import "./index.scss";

export default function Us({ theme }) {
  /*==================================================
  Data
  ==================================================*/
  const { setTheme } = Utils.useContainer();
  const { web3 } = Web3.useContainer();

  // 0x 75726c 20 68747470733a2f2f616e62616e672e626c6f672f
  //           14 68747470733a2f2f616e62616e672e626c6f672f
  const sources = "url https://anbang.blog/";
  const data =
    "0x75726c0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001468747470733a2f2f616e62616e672e626c6f672f000000000000000000000000";
  console.log(`加密:${web3 && web3.utils.asciiToHex(sources)}`);
  console.log(`解析:${web3 && web3.utils.hexToAscii(data)}`);

  /*==================================================
  Function
  ==================================================*/

  /*==================================================
  Handle
  ==================================================*/

  /*==================================================
  UseEffect
  ==================================================*/
  useEffect(() => {
    setTheme(theme);
  }, [theme, setTheme]);

  useEffect(() => {
    document.title = `${CONFIG.WEBSITE} > 关于 ${CONFIG.WEBSITE}`;
  });
  /*==================================================
  DOM
  ==================================================*/
  return (
    <>
      <Header />
      <div className="core about">
        <div className="container">
          <div className="center">
            <h1>关于 iStone</h1>
            <p>
              自从有了文明以来，人类一直在寻找可以永久记录信息的媒介，比如商朝王室将文字记录在龟甲或兽骨上；之后出现了将文字记录在竹片，石碑，羊皮，纸张上的情况。
              现在常用的方式是将文字记录在个人电脑，移动硬盘，互联网应用，但是这些方式对于长久记录数据来说非常不友好。
            </p>
            <p>
              区块链的出现非常伟大，它或许可以永久的记录我们的作品，无论是文字，音乐，或是其它。在信息记录方面，相比中心化产品，区块链有着天然的优势。
              当我们的作品记录在中心化产品时，
              它们可能因为电脑重装系统，移动硬盘丢失/损坏，互联网内容审核，自己误删除，或者账户不可用，产品停止维护等情况导致作品被永久丢失，我们甚至无法恢复这些数据，导致永远的丢失它们。
            </p>
            <p>
              区块链是一个蓬勃发展的行业，这个行业拥有各式各样的公链，不同公链之间的数据天生是被隔离的；默认情况下，我们无法在不同的公链之间进行信息交流。
              iStone的初衷是做一个基于区块链的跨链交流聚合器！通过 iStone
              你可以使用喜欢的公链永久记录你的作品；同理，其他人也可以使用他们喜欢的公链针对你的信息进行点评和互动。虽然我们处在不同的公链上，但这并不会影响我们愉快的交流。
            </p>
            <p>
              因为区块链记录任何信息都是需要付费的，所以iStone的合约会设计的尽可能简单和省钱。追求核心与实用，尽量不做那些花里胡哨的功能。
            </p>
            <h2>iStone 内容说明</h2>
            <p>
              虽然你可以使用区块链永久记录任何作品，但为了维护友好的交流气氛，iStone有权拒绝以下内容的展示。
            </p>
            <ul>
              <li>拒绝政治，色情，毒品相关内容，这些内容会被禁止展示。</li>
              <li>
                辱骂他人的言论会被禁止展示。
                <ul>
                  <li>
                    区块链的每次记录都是需要付费的，请珍惜自己的钱，也尊重他人的阅读时间。请尽量发一些有意义的内容。
                  </li>
                </ul>
              </li>
              <li>
                请不要抄袭和全文转载其他人的作品，如果为了交流，推荐使用链接的方式进行分享。
                <ul>
                  <li>
                    你可以全文转载自己的文章，但如果你在iStone发帖，纯粹为了吸引流量而不是交流互动，请停止这样做。傻瓜式的发广告只会让大家讨厌你。
                  </li>
                  <li>
                    如果iStone上已经有某类产品的推广邀请链接，请不要再重复发这类带有邀请返利形式的链接。
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              提示：区块链上的数据是不可撤销的，请谨慎发布你的作品。你的作品一旦发布，将无法删除。
            </p>
            <div>
              <h2>iStone 合约开源</h2>
              <p>
                iStone
                合约代码开源在Github，也会在所在公链的区块链浏览器上进行验证和代码开源。即使有一天iStone网站因为各种情况关闭了，你的数据也可以通过开源代码轻松获取到。
              </p>
              <p>
                使用区块链记录数据的唯一风险：
                <strong>你发布作品所使用的公链停止维护了</strong>
                ！所以大家在发布自己内容时，请尽量选择主流区块链网络。
              </p>
            </div>
            <div>
              <h2>iStone 来源</h2>
              <p>iStone 的交互参考了百度贴吧，V2EX。</p>
            </div>
            <div>
              <h2>iStone 使用</h2>
              <p>iStone 展示的所有数据均从区块链读取。</p>
              <ul>
                <li>
                  收藏：在主题详情页内，点击"收藏"，可以将你喜欢的主题链接永久储存在区块链中
                </li>
                {/* <li>
                  喜欢：在主题详情页内，点击"喜欢"，可以调取创作者在你所处公链中的钱包地址，你可以进行转账感谢。
                  <ul>
                    <li>
                      例：你处在 Polygon
                      网络，看到一篇Solana网络的主题，或者这个主题中的来自 Tron
                      网络的一条回复。
                      点击"主题的喜欢"，可以调取Solana网络中主题发起者的Ethereum地址。
                      点击"指定回复的喜欢"，可以调取Tron网络中该回复者的Ethereum地址。
                      （这个操作的前提是他们设置了Ethereum地址，否则只能调取他们所在网络的默认地址）
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
            <div>
              <h2>TODO List</h2>
              <ul>
                <li>消息提醒模块</li>
                <li>回复指定楼层</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Outlet />
    </>
  );
}
